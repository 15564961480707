import { Dialog, DialogTitle, DialogContent, Box, IconButton, ButtonGroup, Divider, Typography, Stack, Button } from "@suid/material";

import { createSignal, Show } from "solid-js";
import { StarRating } from '../SmallComponents/SmallShowStars';
import { RatingComment, RatingDetails } from '../SmallComponents/SmallShowRatingComment';
import { FilterListMenu } from '../SmallComponents/SmallListMenu';
import { CloseIcon } from '../icons';
import { getText } from '../SmallComponents/Translate'

interface CandidateRatingProps {
    rating: number;
    numberOfFeedbacks?: number;
    numberOfFeedbacksNotReceived?: number;
    showWithFilter?: boolean;
}


const CandidateRatings = ({ rating, showWithFilter = true, numberOfFeedbacks = 0, numberOfFeedbacksNotReceived = 1 }: CandidateRatingProps) =>{

    const [openDialog, setOpenDialog] = createSignal(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const txtExcellent = getText("rating", "excellent")
    const txtPending = getText("rating", "pending")
    const txtTechnicalcompetence = getText("rating", "techcompetence")
    const txtSocialcompetence = getText("rating", "soccompetence")
    const txtWorkagain = getText("rating", "workagain")

    const txtBasedon = getText("rating", "basedon")
    const txtFeedback = getText("rating", "feedback")
    const txtFeedbacks = getText("rating", "feedbacks") 
    const txtOverall = getText("rating", "overall")
    const txtFeedbackfromnumbers = getText("rating", "feedbackfromnumbers", [{ name: "Number", value: "1" }])

    return (
        <>
            <Show
                when={numberOfFeedbacks}
                fallback={
                    <Button size="small" >{txtPending()}</Button>
                }
            >

                <Stack
                    direction="column"
                >
                    <Stack direction="column" alignItems="Right">

                        <Show when={showWithFilter === true}

                            fallback={
                                <>
                                    <Button size="small" color="success" variant="text" onClick={handleOpenDialog}    >
                                   
                                        <StarRating rating={rating} />
                                        {" "} ({numberOfFeedbacks})
                                    </Button>
                                </>
                            }
                        >
                            <ButtonGroup variant="outlined" size="small" color="success" aria-label="outlined button group">
                                <Button size="small" onClick={handleOpenDialog}   >
                                    
                                    <StarRating rating={rating} />
                                    {" "} ({numberOfFeedbacks})
                                </Button>

                                <FilterListMenu />
                            </ButtonGroup>
                        </Show>

                    </Stack>


                    {openDialog() && (
                        <Dialog open={true} onBackdropClick={handleCloseDialog} >
                            <DialogTitle>

                                <IconButton onClick={handleCloseDialog} > <CloseIcon /> </IconButton>
                                <Typography color="success.dark">
                                    <RatingDetails rating={rating} >{txtOverall()} {rating} {txtExcellent()}</RatingDetails>

                                    <Stack alignItems="center">
                                        <ButtonGroup variant="outlined" size="small" color="success" aria-label="outlined button group">
                                            <FilterListMenu />
                                        </ButtonGroup>
                                    </Stack>
                                </Typography>

                            </DialogTitle>
                            <DialogContent>



                                <Typography fontSize="80%" variant="body2" align="center" color="text.secondary" gutterBottom>{txtBasedon()}
                                    <Show
                                        when={numberOfFeedbacks === 1}
                                        fallback={<> {txtFeedbacks()} </>}
                                    >
                                        {numberOfFeedbacks} {txtFeedback()}<br />
                                    </Show>
                                    <Show
                                        when={numberOfFeedbacksNotReceived}
                                    >
                                        {txtFeedbackfromnumbers()}
                                    </Show>
                                </Typography>
                                <br />
                                <Divider />
                                <Stack direction="row" justifyContent="space-between"  >
                                    <RatingDetails rating={3.4} >{txtTechnicalcompetence()}</RatingDetails>
                                    <RatingDetails rating={5}>{txtSocialcompetence()}</RatingDetails>
                                    <RatingDetails rating={2} showDivider={false} >{txtWorkagain()}</RatingDetails>
                                </Stack>
                                <Divider />

                                <Stack direction="column"   >
                                    <RatingComment
                                        comment="Best SAP consultant I have worked with. 
                              His tech insigth was excellent. He was able to coach the entiere team."
                                        logoUrl="/assets/imgs/CocaCola.png"
                                        ratedBy="Benny Andersen, Project Manager"
                                        ratedByPosition="SAP HANA Upgrade"
                                        showDivider={false}
                                    />

                                    <RatingComment
                                        comment="He did realy understand our situation."
                                        logoUrl="https://mui.com/static/images/cards/paella.jpg"
                                        ratedBy="Any Schlekt, PI Developer"
                                        ratedByPosition="SAP HCM Roll-out"
                                        showDivider={false}
                                    />
                                </Stack>


                            </DialogContent>


                        </Dialog>
                    )}
                </Stack>
            </Show>
        </>
    )
}
export { CandidateRatings }
