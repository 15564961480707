
import { createSignal, createMemo, For, Switch as SolidSwitch, Match, Show, JSX } from "solid-js";
import { useNavigate, A } from "@solidjs/router";
import { Card, Avatar, Divider, Dialog, Chip, DialogContent, Typography, Button, IconButton, Stack, Box } from "@suid/material";

import { WFChip } from '../SmallComponents/SmallChips';

import { RateValueForTable } from '../SmallComponents/SmallRateValue';
import { CandidateRatings } from '../Candidate/CandidateRatingComponent';
import {
    MoreVertIcon, NegativeAnswerIcon, OpenListIcon, CloseListIcon, BusinessIcon, PingIcon, RateIcon, TerminateIcon, MatchingIcon, ContractIcon, ProposalIcon, InterviewIcon,
    PersonIcon, PositiveFeedbackIcon, NegativeFeedbackIcon, JobIcon, JobRoleIcon, JobHistoryIcon
} from '../icons';
import { extractInitials } from "~/utils/extractInitials";
import { getMinimum } from "~/utils/utilnumbers";
import { isExtraSmall, isSmall } from "~/utils/sizeUtil";
import { getText, GetTextUI } from '../SmallComponents/Translate'

interface ShowWfItemsType {
    avatarName: boolean;

    customerViewJob: boolean;
    customerViewJobRole: boolean;
    customerViewJobAssignment: boolean;
    customerViewCV: boolean;
    customerConfirmOnMatchList: boolean,
    customerSendInterviewInvite: boolean;
    customerSendInterviewReschedule: boolean;
    customerSendInterviewCancelation: boolean;
    customerSendPositiveFeedback: boolean;
    customerSendProposal: boolean;
    customerSendContract: boolean;
    customerSignContract: boolean;
    customerSendContractExtension: boolean;
    customerSendNegativeFeedback: boolean;
    customerSendContractTermination: boolean;
    customerRateCandidate: boolean;
    customerPutOnGreylist: boolean;
    customerPingCandidate: boolean;
    customerViewMatching: boolean;
    customerDeclineMatchedCandidate: boolean;

    candidateViewJob: boolean;
    candidateViewJobRole: boolean;
    canddiateViewJobAssignment: boolean;
    candidateViewCV: boolean;
    candidateViewMatching: boolean;
    candidateSendIAmInterested: boolean;
    candidateSendPositiveFeedback: boolean;
    candidateSendNegativeFeedback: boolean;
    candidateSendCounterProposal: boolean;
    candidateAcceptInterviewInvite: boolean;
    candidateAcceptProposal: boolean;
    candidateAcceptContract: boolean;
    candidateAcceptTermination: boolean;
    candidateSignContract: boolean;
    candidateTerminateContract: boolean;
    candidateAcceptPing: boolean;
    candidateDeclinePingInvite: boolean;
    candidateDeclineMatchedJob: boolean;
    candidateDeclineInterviewInvite: boolean;
    candidateViewCoWorksersCV: boolean;
    candidateViewCoWorksersJobRole: boolean;
    candidateRateCoWorker: boolean;
    candidateAskCoWorkerForRating: boolean;
    candidateAskForContractExtension: boolean;
    candidateShareJob: boolean;
    candidatePutOnGreylist: boolean;
    candidateEditJob: boolean;
}

const createWfItems = (overrides: Partial<ShowWfItemsType>): ShowWfItemsType => {
    return {
        avatarName: true,

        customerViewJob: false,
        customerViewJobRole: false,
        customerViewJobAssignment: false,
        customerViewCV: false,
        customerConfirmOnMatchList: false,
        customerSendInterviewInvite: false,
        customerSendInterviewReschedule: false,
        customerSendInterviewCancelation: false,
        customerSendPositiveFeedback: false,
        customerSendProposal: false,
        customerSendContract: false,
        customerSignContract: false,
        customerSendContractExtension: false,
        customerSendNegativeFeedback: false,
        customerSendContractTermination: false,
        customerRateCandidate: false,
        customerPutOnGreylist: false,
        customerPingCandidate: false,
        customerViewMatching: false,
        customerDeclineMatchedCandidate: false,

        candidateViewJob: false,
        candidateViewJobRole: false,
        canddiateViewJobAssignment: false,
        candidateViewCV: false,
        candidateViewMatching: false,
        candidateSendIAmInterested: false,
        candidateSendPositiveFeedback: false,
        candidateSendNegativeFeedback: false,
        candidateSendCounterProposal: false,
        candidateAcceptInterviewInvite: false,
        candidateAcceptProposal: false,
        candidateAcceptContract: false,
        candidateAcceptTermination: false,
        candidateTerminateContract: false,
        candidateSignContract: false,
        candidateAcceptPing: false,
        candidateDeclinePingInvite: false,
        candidateDeclineMatchedJob: false,
        candidateDeclineInterviewInvite: false,
        candidateViewCoWorksersCV: false,
        candidateViewCoWorksersJobRole: false,
        candidateRateCoWorker: false,
        candidateAskCoWorkerForRating: false,
        candidateAskForContractExtension: false,
        candidateShareJob: false,
        candidatePutOnGreylist: false,
        candidateEditJob: false,
        ...overrides,
    };
};

const initialShowWfItems = createWfItems({});

// Customer Matching WF
export const showWfCustomerMatchingOpendItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerPingCandidate: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerSendInterviewInvite: true,
    customerDeclineMatchedCandidate: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerMatchingNotSelectedItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerSendInterviewInvite: true,
    customerPutOnGreylist: true,
});

// Customer Jobs WF
export const showWfCustomerJobsOpenItems = createWfItems({
    customerViewJobRole: true,
    customerViewMatching: true,
});
export const showWfCustomerJobsBestMatchItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerSendInterviewInvite: true,
    customerSendProposal: true,
    customerDeclineMatchedCandidate: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerJobRolesItems = createWfItems({
    customerViewCV: true,
    customerViewJobAssignment: true,
    customerSendInterviewInvite: true,
    customerSendProposal: true,
    customerSendContractExtension: true,
    customerSendContractTermination: true,
    customerDeclineMatchedCandidate: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerJobsStaffedItems = createWfItems({
    customerViewCV: true,
    customerViewJobAssignment: true,
    customerSendContractExtension: true,
    customerSendContractTermination: true,
    customerRateCandidate: true,
});
export const showWfCustomerJobsNotStaffedItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerViewJobAssignment: true,
    customerPutOnGreylist: true,
});

// Customer To-Do WF
export const showWfCustomerToDoGrossListItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
});
export const showWfCustomerToDoContractItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerViewJobAssignment: true,
    customerSendContract: true,
    customerSendContractExtension: true,
    customerSignContract: true,
    customerSendContractTermination: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerToDoProposalItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerViewJobAssignment: true,
    customerSendProposal: true,
    customerSendContract: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerToDoIncommingMatchesItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerConfirmOnMatchList: true,
    customerSendProposal: true,
    customerSendInterviewInvite: true,
    customerSendPositiveFeedback: true,
    customerDeclineMatchedCandidate: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerToDoNotConfirmedInterviewItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerViewJobAssignment: true,
    customerSendProposal: true,
    customerSendInterviewInvite: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerToDoConfirmedInterviewItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerViewJobAssignment: true,
    customerSendProposal: true,
    customerSendInterviewReschedule: true,
    customerSendInterviewCancelation: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerPutOnGreylist: true,
});

export const showWfCustomerToDoOngoingItems = createWfItems({
    customerViewCV: true,
    customerViewJobRole: true,
    customerViewJobAssignment: true,
    customerDeclineMatchedCandidate: true,
    customerSendInterviewInvite: true,
    customerSendProposal: true,
    customerSendPositiveFeedback: true,
    customerSendNegativeFeedback: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerToDoCompletedItems = createWfItems({
    customerViewCV: true,
    customerViewJobAssignment: true,
    customerSendPositiveFeedback: true,
    customerPutOnGreylist: true,
});
export const showWfCustomerToDoOpenItems = createWfItems({
    customerViewJobRole: true,
    customerViewMatching: true,
});

//
// Candidate WF
//

// Candidate Matching WF
export const showWfCandidateMatchSneakPreviewItems = createWfItems({
    candidateSendIAmInterested: true,
    candidateDeclineMatchedJob: true,
});
export const showWfCandidateMatchNotSelectedItems = createWfItems({
    candidateViewJobRole: true,
});

// Candidate To-Do WF
export const showWfCandidateToDoPendingInterviewsItems = createWfItems({
    candidateViewJobRole: true,
    candidateAcceptInterviewInvite: true,
    candidateDeclineInterviewInvite: true,
    candidateShareJob: true,
});
export const showWfCandidateToDoInterviewsItems = createWfItems({
    candidateViewJobRole: true,
    candidateDeclineInterviewInvite: true,
    candidateShareJob: true,
});
export const showWfCandidateToDoProposalItems = createWfItems({
    canddiateViewJobAssignment: true,
    candidateAcceptProposal: true,
    candidateSendCounterProposal: true,
    candidateSendPositiveFeedback: true,
    candidateSendNegativeFeedback: true,
});
export const showWfCandidateToDoContractItems = createWfItems({
    canddiateViewJobAssignment: true,
    candidateAcceptContract: true,
    candidateTerminateContract: true,
    candidateSendCounterProposal: true,
    candidateSendNegativeFeedback: true,
    candidateSignContract: true,
});
export const showWfCandidateToDoTerminateContractItems = createWfItems({
    canddiateViewJobAssignment: true,
    candidateAcceptTermination: true,
});
export const showWfCandidateRecommendationItems = createWfItems({
    candidateRateCoWorker: true,
    candidateAskCoWorkerForRating: true,
});
export const showWfCandidateFeedbackItems = createWfItems({
    candidateViewCoWorksersCV: true,
    candidateRateCoWorker: true,
    candidateAskCoWorkerForRating: true,
});

export const showWfCandidateCustomerItems = createWfItems({
    candidateEditJob: true,
    candidateRateCoWorker: true,
    candidateAskCoWorkerForRating: true,
});

// Candidate Jobs WF
export const showWfCandidateJobsBestMatchItems = createWfItems({
    candidateViewJobRole: true,
    candidateSendIAmInterested: true,
    candidateDeclineMatchedJob: true,
    candidateShareJob: true,
    candidatePutOnGreylist: true,
});
export const showWfCandidateJobsNotStaffedItems = createWfItems({
    candidateViewJobRole: true,
    candidateSendIAmInterested: true,
    candidateShareJob: true,
});
export const showWfCandidateJobsStaffedItems = createWfItems({
    candidateViewCoWorksersCV: true,
    candidateViewCoWorksersJobRole: true,
});

export const showWfCandidateOngoingJobRoleItems = createWfItems({
    candidateViewJobRole: true,
    candidateSendPositiveFeedback: true,
    candidateAskForContractExtension: true,
    candidateTerminateContract: true,
    candidateSendNegativeFeedback: true,
});
export const showWfCandidateCompletedJobRoleItems = createWfItems({
    candidateViewJobRole: true,
});

// Candidate xxxxxx WF
export const showWfCandidateAcceptProposalsItems = createWfItems({
    canddiateViewJobAssignment: true,
    candidateAcceptProposal: true,
    candidateSendCounterProposal: true,
});
export const showWfCandidateSignContractsItems = createWfItems({
    canddiateViewJobAssignment: true,
    candidateAcceptContract: true,
    candidateSendCounterProposal: true,

    candidateSendNegativeFeedback: true,
    candidateSignContract: true,
});
export const showWfCandidateCV = createWfItems({
    customerViewJobRole: true,
    customerViewJobAssignment: true,
});

interface AvatarWithWfButtonsProps {
    src: string | undefined;
    idType?: string;
    id?: string;
    jobId?: string;
    jobRoleId?: string;
    jobAssignmentId?: string;
    candidateId?: string;
    interviewId?: string;
    proposalId?: string;
    contractId?: string;
    avatarName?: string;
    jobCustomer?: string;
    jobName?: string;
    role?: string;
    roleDescription?: string;
    wfStatus?: string;
    highlightComment?: string;
    interviewDate?: string;
    interviewType?: string;
    onSiteText?: string;
    workOnsite?: string;
    workRemote?: string;
    jobStartDate?: string;
    jobEndDate?: string
    rateFlat?: string;
    rateOnsite?: string;
    rateRemote?: string;
    currency?: string;
    direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
    avatarSize?: "small" | "medium" | "large"
    showWfItems?: ShowWfItemsType;
    rating?: number;
    numberOfRatings?: number;
    anonymized?: "no" | "name" | "avatar" | "nameAndAvatar" | "editModus";
    jobDescription?: string;
    jobAccomplishment?: string;
    jobSize?: string;
    locationCountry?: string;
    locationCity?: string;
}

const AvatarWithWfButtons = ({ src, showWfItems = initialShowWfItems, idType = "", id = "", interviewId = "", proposalId = "", contractId = "", jobId = "", jobRoleId = "", jobAssignmentId ="",candidateId = "", avatarName = "", jobCustomer = "", jobName = "", role = "", roleDescription = "", workOnsite = "", workRemote = "", onSiteText = "", jobStartDate = "", jobEndDate = "", wfStatus = "", direction = "column", avatarSize = "large", rating = 0, numberOfRatings = 0, anonymized = "no", interviewDate = "", interviewType = "", jobDescription = "", jobAccomplishment = "", jobSize = "", locationCountry = "", locationCity = "", rateFlat = "", rateOnsite = "", rateRemote = "", currency = "", highlightComment = "" }: AvatarWithWfButtonsProps) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const [popoverText, setPopoverText] = createSignal("");

    const openPopover = () => Boolean(anchorEl());
    const handleOpenPopover = (txt: string) => (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
        setPopoverText(txt)
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const [showElementId, setShowElementId] = createSignal(initialShowWfItems);

    const handleShowWorkflowButtons = () => {
        setShowElementId(showWfItems)
    };
    const handleHideWorkflowButtons = () => {
        setShowElementId(initialShowWfItems)
    };

    const [selectedId, setSelectedId] = createSignal(id ? id : "undefined");

    if (idType === "job")
        setSelectedId(jobId ? jobId : "undefined");
    else if (idType === "jobrole")
        setSelectedId(jobRoleId ? jobRoleId : "undefined");
    else if (idType === "jobassignment")
        setSelectedId(jobAssignmentId ? jobAssignmentId : "undefined");
    else if (idType === "candidate")
        setSelectedId(candidateId ? candidateId : "undefined");
    else if (idType === "interview")
        setSelectedId(interviewId ? interviewId : "undefined");
    else if (idType === "proposal")
        setSelectedId(proposalId ? proposalId : "undefined");
    else if (idType === "contract")
        setSelectedId(contractId ? contractId : "undefined");

    let mappingId = "undefined";
    createMemo(() => {
        mappingId = selectedId()
    })

    interface ActionButtonProps {
        onMouseEnterText: string;
        onClickRoute: string;
        startIcon?: JSX.Element;
        color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
        children: JSX.Element;
        size?: "large" | "small" | "medium"
    }

    const elementsMapping: { condition: keyof ShowWfItemsType; config: ActionButtonProps }[] = [

        {
            condition: 'candidateEditJob',
            config: {
                onMouseEnterText: "Update information for the selected job",
                onClickRoute: "/candidatejobedit",
                startIcon: <JobIcon />,
                children: <span>Update Job</span>
            }
        },
        {
            condition: 'candidateSendPositiveFeedback',
            config: {
                onMouseEnterText: "Send a positive message to the customer to stay in the loop",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <PositiveFeedbackIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Positive Feedback</span> : <span>Prepare Positive Feedback</span>
            }
        }, {
            condition: 'candidateSendNegativeFeedback',
            config: {
                onMouseEnterText: "Notify the customer about your situation",
                onClickRoute: "/candidateworkflow/candidateSendNegativeFeedback/" + mappingId,
                startIcon: <NegativeFeedbackIcon />,
                color: "error",
                children: isExtraSmall() ? <span>Negative Feedback</span> : <span>Prepare Negative Feedback</span>
            }
        },
        {
            condition: 'candidatePutOnGreylist',
            config: {
                onMouseEnterText: "Set customer on a greylist and dont present jobs again from the customer",
                onClickRoute: "/candidateworkflow/candidatePutOnGreylist",
                startIcon: <TerminateIcon />,
                color: "error",
                children: <span>Blok Customer</span>
            }
        },
        {
            condition: 'candidateShareJob',
            config: {
                onMouseEnterText: "Share job with others",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <RateIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Hint about Job</span> : <span>Hint another about Job</span>
            }
        }, {
            condition: 'candidateAskCoWorkerForRating',
            config: {
                onMouseEnterText: "Ask coworker for feedback",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <RateIcon />,
                color: "success",
                children: <span>Ask for Feedback</span>
            }
        },
        {
            condition: 'candidateRateCoWorker',
            config: {
                onMouseEnterText: "Rate one or more of your coworkers",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <RateIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Co-worker Feedback</span> : <span>Feedback to Co-worker</span>
            }
        },
        {
            condition: 'candidateViewCoWorksersCV',
            config: {
                onMouseEnterText: "View CV",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <PersonIcon />,
                children: <span>View CV</span>
            }
        },
        {
            condition: 'candidateViewCoWorksersCV',
            config: {
                onMouseEnterText: "View job role",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + candidateId,
                startIcon: <JobIcon />,
                children: <span>View Job Role</span>
            }
        },
        {
            condition: 'candidateDeclineInterviewInvite',
            config: {
                onMouseEnterText: "Decline interview invite",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + interviewId,
                startIcon: <InterviewIcon />,
                color: "error",
                children: <span>Decline Interview</span>
            }
        },
        {
            condition: 'candidateDeclineMatchedJob',
            config: {
                onMouseEnterText: "Decline job found by smart match",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <MatchingIcon />,
                color: "error",
                children: <span>Decline Matched Job</span>
            }
        },
        {
            condition: 'candidateDeclinePingInvite',
            config: {
                onMouseEnterText: "Decline incomming ping and skip other further possible jobs from customer",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <PingIcon />,
                color: "error",
                children: <span>Decline Ping</span>
            }
        },
        {
            condition: 'candidateAcceptPing',
            config: {
                onMouseEnterText: "Accept incomming ping and share information",
                onClickRoute: "/candidateworkflow/candidateSendPositiveFeedback/" + mappingId,
                startIcon: <PingIcon />,
                color: "success",
                children: <span>Accept Ping</span>
            }
        },
        {
            condition: 'candidateAcceptContract',
            config: {
                onMouseEnterText: "Accept contract freceived from customer",
                onClickRoute: "/candidateworkflow/candidateAcceptContract/" + contractId,
                startIcon: <ContractIcon />,
                color: "success",
                children: <span>Accept Contract</span>
            }
        },
        {
            condition: 'candidateSignContract',
            config: {
                onMouseEnterText: "Sign contract",
                onClickRoute: "/candidateworkflow/candidateSignContract/" + contractId,
                startIcon: <ContractIcon />,
                color: "success",
                children: <span>Sign Contract</span>
            }
        },
        {
            condition: 'candidateAcceptTermination',
            config: {
                onMouseEnterText: "Prepare Terminate Accept",
                onClickRoute: "/candidateworkflow/candidateTerminateAccept/" + contractId,
                startIcon: <ContractIcon />,
                color: "success",
                children: <span>Accept Termination</span>
            }
        },
        {
            condition: 'candidateTerminateContract',
            config: {
                onMouseEnterText: "Terminate contract with customer",
                onClickRoute: "/candidateworkflow/candidateTerminateContract/" + contractId,
                startIcon: <ContractIcon />,
                color: "error",
                children: <span>Terminate Contract</span>
            }
        },
        {
            condition: 'candidateAcceptProposal',
            config: {
                onMouseEnterText: "Accept proposal and send it for signing",
                onClickRoute: "/candidateworkflow/candidateAcceptProposal/" + proposalId,
                startIcon: <ProposalIcon />,
                color: "success",
                children: <span>Accept Proposal</span>
            }
        },
        {
            condition: 'candidateSendCounterProposal',
            config: {
                onMouseEnterText: "Send your proposed commercial & terms",
                onClickRoute: "/candidateworkflow",
                startIcon: <ProposalIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Counter Proposal</span> : <span>Prepare Counter Proposal</span>
            }
        },
        {
            condition: 'candidateAcceptInterviewInvite',
            config: {
                onMouseEnterText: "Accept the proposed interview",
                onClickRoute: "/candidateworkflow",
                startIcon: <InterviewIcon />,
                color: "success",
                children: <span>Accept Invite</span>
            }
        },
        {
            condition: 'candidateAskForContractExtension',
            config: {
                onMouseEnterText: "Ask for a contract extension",
                onClickRoute: "/candidateworkflow",
                startIcon: <ContractIcon />,
                color: "success",
                children: <span>Ask for Contract Extension</span>
            }
        },
        {
            condition: 'customerViewCV',
            config: {
                onMouseEnterText: "View candidate CV",
                onClickRoute: "/viewcv/" + candidateId,
                startIcon: <PersonIcon />,
                children: <span>View CV</span>
            }
        },

        {
            condition: 'customerConfirmOnMatchList',
            config: {
                onMouseEnterText: "Keep the candidate on the match list",
                onClickRoute: "/customerworkflow",
                startIcon: <MatchingIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Confirm match list</span> : <span>Confirm on match list</span>
            }
        },

        {
            condition: 'customerSendInterviewInvite',
            config: {
                onMouseEnterText: "Send invitation for Interview",
                onClickRoute: "/customerworkflow",
                startIcon: <InterviewIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Interview Invitation</span> : <span>Prepare Interview Invitation</span>
            }
        },
        {
            condition: 'customerSendInterviewReschedule',
            config: {
                onMouseEnterText: "Reschedule interview and send updated invitation",
                onClickRoute: "/customerworkflow",
                startIcon: <InterviewIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Reschedule</span> : <span>Reschedule Interview</span>
            }
        },
        {
            condition: 'customerSendInterviewCancelation',
            config: {
                onMouseEnterText: "Send invitation cancelation",
                onClickRoute: "/customerworkflow",
                startIcon: <InterviewIcon />,
                color: "error",
                children: <span>Cancel Interview</span>
            }
        },

        {
            condition: 'customerSendPositiveFeedback',
            config: {
                onMouseEnterText: "Send positive message",
                onClickRoute: "/customerworkflow",
                startIcon: <PositiveFeedbackIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Positive Message</span> : <span>Prepare Positive Message</span>
            }
        },
        {
            condition: 'customerSendNegativeFeedback',
            config: {
                onMouseEnterText: "Send negative message",
                onClickRoute: "/customerworkflow",
                startIcon: <NegativeFeedbackIcon />,
                color: "error",
                children: isExtraSmall() ? <span>Negative Message</span> : <span>Prepare Negative Message</span>

            }
        },
        {
            condition: 'customerDeclineMatchedCandidate',
            config: {
                onMouseEnterText: "Remove the candidate from the list",
                onClickRoute: "/customerworkflow",
                startIcon: <NegativeFeedbackIcon />,
                color: "error",
                children: isExtraSmall() ? <span>Remove From Match</span> : <span>Remove From Match List</span>

            }
        },

        {
            condition: 'customerPutOnGreylist',
            config: {
                onMouseEnterText: "Put candidate on greylist",
                onClickRoute: "/customerworkflow",
                startIcon: <TerminateIcon />,
                color: "error",
                children: <span>Blok Candidate</span>

            }
        },
        {
            condition: 'customerSendContractTermination',
            config: {
                onMouseEnterText: "Send Contract Termination",
                onClickRoute: "/customerworkflow",
                startIcon: <NegativeAnswerIcon />,
                color: "error",
                children: isExtraSmall() ? <span>Prepare Termination</span> : <span>Prepare Contract Termination</span>

            }
        },
        {
            condition: 'customerSendProposal',
            config: {
                onMouseEnterText: "Proceed with proposal",
                onClickRoute: "/customerworkflow",
                startIcon: <ProposalIcon />,
                color: "success",
                children: <span>Prepare Proposal </span>
            }
        },
        {
            condition: 'customerPingCandidate',
            config: {
                onMouseEnterText: "Ping candidate to unlock additonal information",
                onClickRoute: "/customerworkflow",
                startIcon: <PingIcon />,
                color: "success",
                children: <span>Ping Candidate </span>
            }
        },

        {
            condition: 'customerSendContract',
            config: {
                onMouseEnterText: "Proceed with contract",
                onClickRoute: "/customerworkflow",
                startIcon: <ContractIcon />,
                color: "success",
                children: <span>Prepare Contract</span>
            }
        },
        {
            condition: 'customerSignContract',
            config: {
                onMouseEnterText: "Proceed with signing of contract",
                onClickRoute: "/customerworkflow",
                startIcon: <ContractIcon />,
                color: "success",
                children: <span>Sign Contract</span>
            }
        },
        {
            condition: 'customerSendContractExtension',
            config: {
                onMouseEnterText: "Proceed with contract extension",
                onClickRoute: "/customerworkflow",
                startIcon: <ContractIcon />,
                color: "success",
                children: isExtraSmall() ? <span>Prepare Extension</span> : <span>Prepare Contract Extension</span>
            }
        },
        {
            condition: 'customerViewMatching',
            config: {
                onMouseEnterText: "Match role with candidate",
                onClickRoute: "/customermatching",
                startIcon: <MatchingIcon />,
                color: "success",
                children: <span>Run Matching</span>,

            }
        },
        {
            condition: 'customerViewJobAssignment',
            config: {
                onMouseEnterText: "View assignment overview",
                onClickRoute: "/customerjobassignmentview/" + jobAssignmentId,
                startIcon: <JobHistoryIcon />,
                children: <span>View Job Assignment</span>
            }
        },
        {
            condition: 'customerViewJobRole',
            config: {
                onMouseEnterText: "View job role", 
                onClickRoute: "/customerjobroleview/" + jobRoleId,
                startIcon: <JobRoleIcon />,
                children: <span>View Job Role</span>
            }
        },
        {
            condition: 'customerViewJob',
            config: {
                onMouseEnterText: "View job",
                onClickRoute: "/customerjob",
                startIcon: <JobIcon />,
                children: <span>View Job</span>
            }
        },
        {
            condition: 'customerRateCandidate',
            config: {
                onMouseEnterText: "Rate candidate ",
                onClickRoute: "/customerworkflow",
                startIcon: <RateIcon />,
                color: "success",
                children: <span>Rate Candidate</span>
            }
        },


        {
            condition: 'candidateViewJob',
            config: {
                onMouseEnterText: "View job",
                onClickRoute: "/candidatejob/" + jobId,
                startIcon: <JobIcon />,
                children: <span>View Job</span>
            }
        },
        {
            condition: 'candidateViewJobRole',
            config: {
                onMouseEnterText: "View role",
                onClickRoute: "/myjobrole/" + jobRoleId,
                startIcon: <JobIcon />,
                children: <span>View Job Role</span>
            }
        },
        {
            condition: 'canddiateViewJobAssignment',
            config: {
                onMouseEnterText: "View assignment",
                onClickRoute: "/myjobrole/" + jobRoleId,
                startIcon: <JobIcon />,
                children: <span>View Job Assignment</span>
            }
        },
        {
            condition: 'candidateViewMatching',
            config: {
                onMouseEnterText: "Match role with job",
                onClickRoute: "/candidatematching",
                startIcon: <MatchingIcon />,
                children: <span>View Job Matching</span>,

            }
        },
        {
            condition: 'candidateSendIAmInterested',
            config: {
                onMouseEnterText: "Mark the job as interested",
                onClickRoute: "/candidatematching",
                startIcon: <MatchingIcon />,
                color: "success",
                children: <span>I am interested</span>,

            }
        },
        {
            condition: 'candidateViewCV',
            config: {
                onMouseEnterText: "View candidate CV",
                onClickRoute: "/viewcv",
                startIcon: <PersonIcon />,
                children: <span>View CV</span>
            }
        }
    ];

    const ActionButton = (props: ActionButtonProps) => {

        const handleNavigateRoute = (name: string) => {
            const url = selectedId() ? name + "/" + jobRoleId : name
            //const url = selectedId() ? name+"/"+selectedId() : name
            //const url = name
            navigate(url);
        }

        const commonProps = {
            onMouseEnter: handleOpenPopover(props.onMouseEnterText),
            onMouseLeave: handleClosePopover,
            onClick: handleNavigateRoute,
            color: props.color,
            size: props.size,
        };

        if (props.startIcon) {

            return (
                <>
                    <A href={props.onClickRoute} onMouseEnter={() => handleOpenPopover(props.onMouseEnterText)} onMouseLeave={handleClosePopover} onClick={() => handleNavigateRoute(props.onClickRoute)}>
                        <Button
                            variant="outlined"
                            size={props.size || "small"}
                            sx={{ minWidth: 250 }}

                            color={props.color}
                            startIcon={props.startIcon}
                        >
                            {props.children}
                        </Button>
                    </A>
                </>
            )
        }
        return <IconButton size={props.size ? props.size : "large"} onMouseEnter={handleOpenPopover(props.onMouseEnterText)} onMouseLeave={handleClosePopover} onClick={() => handleNavigateRoute(props.onClickRoute)} color={props.color}  > {props.startIcon} </IconButton>

    }

    const DrawWfButtonContent = () => {
        const getPriority = (item: any) => {
            if (item.config.color === "success") {
                return 2;
            } else if (item.config.color === "error") {
                return 3;
            }
            return 1;
        }

        // Sort the elementsMapping array based on the given priorities
        const sortedElementsMapping = [...elementsMapping].sort((a, b) => getPriority(a) - getPriority(b));
        return (
            <>
                <For
                    each={sortedElementsMapping}
                >
                    {(item) => (
                        <Show when={showElementId()[item.condition as keyof typeof initialShowWfItems]}>
                            <Show when={isExtraSmall()} fallback={<ActionButton {...item.config} />}>
                                <ActionButton size="large" {...item.config} startIcon={undefined} />
                            </Show>
                        </Show>
                    )}
                </For>
            </>
        )
    }

    const [openDialog, setOpenDialog] = createSignal(false);
    const handleOpenDialog = () => {
        handleShowWorkflowButtons()
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        handleHideWorkflowButtons()
        setOpenDialog(false);
        // props.handleCloseEvent && props.handleCloseEvent();
    }
    const [showMore, setShowMore] = createSignal(false);
    const handleShowMore = () => {
        setShowMore(!showMore())
    }

    const initials = extractInitials(avatarName)
    const txtAnonymized = getText("default", "anonymized")
    const txtAllocationUnits = getText("smallcomponent", "hours")

    const avatarW = (avatarSize === "large" ? 180 : (avatarSize === "medium" ? 120 : 56))
    const avatarH = avatarW
    const letterAvatarW = (avatarSize === "large" ? 120 : (avatarSize === "medium" ? 80 : 50))
    const letterAvatarH = letterAvatarW;
    const avatarVariant = (avatarSize === "large" ? "rounded" : (avatarSize === "medium" ? "rounded" : "circular"))
    const avatarSrc = anonymized !== "no" && anonymized !== "editModus" ? "" : src
    const avatarTxt = anonymized !== "no" && anonymized !== "editModus" ? txtAnonymized() : avatarName

  
      
    return (
        <>

            <Box position="relative" >

                <Stack direction={direction === "row" ? "row" : "column"} alignItems="center">
                    <Card
                        sx={{

                            backgroundColor: "var(--find-bg-color-card)"
                        }}
                    >
                        <Box position="relative" sx={{ display: 'inline-flex' }}>
                            <SolidSwitch fallback={<div><GetTextUI formName={"default"} label={"notfound"} /></div>}>
                                <Match when={avatarSrc} >
                                    <Avatar sx={{ margin: 1, display: 'flex', width: avatarW, height: avatarH }} variant={avatarVariant} src={avatarSrc} >  </Avatar>
                                </Match>
                                <Match when={!avatarSrc && anonymized === "no"}>

                                    <Avatar sx={{ margin: 1, display: 'flex', width: avatarW, height: avatarH }} variant={avatarVariant}   > <BusinessIcon fontSize="large" /> </Avatar>
                                </Match>
                                <Match when={!avatarSrc && anonymized !== "no"}>
                                    <Avatar sx={{ margin: 1, display: 'flex', width: letterAvatarW, height: letterAvatarH }}   > {anonymized !== "no" ? <BusinessIcon fontSize="large" /> : initials} </Avatar>
                                </Match>
                            </SolidSwitch >

                            <Show when={showWfItems !== initialShowWfItems}>
                                <Show when={!avatarSrc}
                                    fallback={
                                        <Box position="absolute" right={2} bottom={5} >
                                            <IconButton color="success" onClick={handleOpenDialog} ><MoreVertIcon /></IconButton>
                                        </Box>
                                    }
                                >
                                    <Box position="absolute" left={(letterAvatarW / 2) - 10} bottom={5} >
                                        <IconButton color="success" onClick={handleOpenDialog} ><MoreVertIcon /></IconButton>
                                    </Box>
                                </Show>
                            </Show>
                            <Show when={rateFlat || rateOnsite || rateRemote} >
                                <Box position="absolute" alignContent="center" alignItems="center" left={8} top={8} sx={{ backgroundColor: "rgba(50, 168, 82, 0.6)" }}>
                                    <RateValueForTable displayType="smallPrimary" value={getMinimum(rateFlat,rateOnsite,rateRemote)} country={"DK"} currency={currency} primaryRateModel={"hour"} />
                                 </Box>
                            </Show>
                        </Box>
                        <Stack direction={"column"} >

                            <Show when={jobCustomer} >
                                <Typography sx={{ px: 1 }} fontSize="80%" variant="body1" color="text.primary">{jobCustomer} </Typography>
                            </Show>
                            <Show when={showElementId().avatarName} >
                                <Typography sx={{ px: 1 }} variant="body1" color="text.primary">{avatarTxt} </Typography>
                                <Typography sx={{ px: 1 }} variant="body2" color="text.secondary">{role}  </Typography>
                                <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{jobName}  </Typography>
                                <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="success.dark">{wfStatus}</Typography>
                            </Show>
                            <Show when={highlightComment} >
                                <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="success.dark">{highlightComment}</Typography>
                            </Show>

                            <Show when={locationCountry || locationCity || jobDescription || jobAccomplishment || jobSize || onSiteText || jobStartDate || jobEndDate || rateFlat || rateOnsite || rateRemote} >
                                <Stack margin={1} >
                                    <Divider />

                                    <Box alignContent="left">
                                        <Button onClick={handleShowMore} color="inherit" size="small" variant="text" endIcon={showMore() ? <OpenListIcon /> : <CloseListIcon />}>
                                            <Typography fontSize="80%" variant="body2" color="text.secondary">
                                                {showMore() ? <GetTextUI formName={"default"} label={"less"} /> : <GetTextUI formName={"default"} label={"more"} />}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Stack>

                            </Show>

                            <Show when={showMore()} >
                                <Show when={interviewDate} >
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"interview"} /><br /></Typography>
                                    <Stack margin={1} spacing={1}>
                                        <WFChip type="candidate" wfCompleted={false} category='schedule' >{interviewDate}</WFChip>
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="success.dark">{interviewType}</Typography>
                                    </Stack>

                                </Show>
                                <Show when={interviewType && !interviewDate} >
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"interviewcomponent"} label={"interviewtype"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="success.dark">{interviewType}</Typography>
                                </Show>
                                <Show when={anonymized === "editModus"} >
                                    <Typography sx={{ px: 1 }} fontSize="70%" variant="body2" color="primary">
                                        <GetTextUI formName={"workflowpage"} label={"txtAnonymizedExplain"} /><br /><br />
                                    </Typography>
                                </Show>

                                <Show when={anonymized !== "no"} >
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"joblocation"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{locationCountry}</Typography>
                                </Show>
                                <Show when={anonymized === "no" && locationCountry}>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"joblocation"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{locationCountry} {locationCity}</Typography>
                                </Show>

                                <Show when={jobStartDate || jobEndDate}>
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"default"} label={"duration"} /><br /></Typography>
                                    <Show when={jobStartDate}>
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary"><GetTextUI formName={"default"} label={"start"} /> {jobStartDate}<br /></Typography>
                                    </Show>
                                    <Show when={jobEndDate}>
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary"><GetTextUI formName={"default"} label={"end"} /> {jobEndDate}<br /></Typography>
                                    </Show>
                                </Show>

                                <Show when={workOnsite || workRemote} >
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  > {txtAllocationUnits()} {onSiteText}<br /></Typography>

                                    <Show when={workOnsite} >
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{workOnsite}  <GetTextUI formName={"smallcomponent"} label={"onsite"} /><br /></Typography>
                                    </Show>
                                    <Show when={workRemote} >
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{workRemote}  <GetTextUI formName={"smallcomponent"} label={"remote"} /><br /></Typography>
                                    </Show>

                                </Show>

                                <Show when={rateFlat || rateOnsite || rateRemote} >
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"smallcomponent"} label={"rate"} /><br /></Typography>

                                    <Show when={rateFlat} >
                                        <Stack direction="row" alignItems="center">
                                            <RateValueForTable displayType="smallGreen" value={Number(rateFlat)} country={"DK"} currency={currency} primaryRateModel={"hour"} />
                                            <Typography sx={{ px: 1 }} fontSize="80%" color="text.secondary" variant="body2"  ><GetTextUI formName={"smallcomponent"} label={"flat"} /><br /></Typography>
                                        </Stack>
                                    </Show>
                                    <Show when={rateOnsite} >
                                        <Stack direction="row" alignItems="center">
                                            <RateValueForTable displayType="smallGreen" value={Number(rateOnsite)} country={"DK"} currency={currency} primaryRateModel={"hour"} />
                                            <Typography sx={{ px: 1 }} fontSize="80%" color="text.secondary" variant="body2"  ><GetTextUI formName={"smallcomponent"} label={"onsite"} /><br /></Typography>
                                        </Stack>
                                    </Show>
                                    <Show when={rateRemote} >
                                        <Stack direction="row" alignItems="center">
                                            <RateValueForTable displayType="smallGreen" value={Number(rateRemote)} country={"DK"} currency={currency} primaryRateModel={"hour"} />
                                            <Typography sx={{ px: 1 }} fontSize="80%" color="text.secondary" variant="body2"  ><GetTextUI formName={"smallcomponent"} label={"remote"} /><br /></Typography>
                                        </Stack>
                                    </Show>
                                </Show>
                                <Show when={jobDescription} >
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"jobdescription"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{jobDescription}</Typography>
                                </Show>
                                <Show when={roleDescription} >
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"roledescription"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{roleDescription}</Typography>
                                </Show>
                                <Show when={jobAccomplishment} >
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"jobaccomplishment"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{jobAccomplishment}</Typography>
                                </Show>
                                <Show when={jobSize} >
                                    <br />
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2"  ><GetTextUI formName={"workflowpage"} label={"jobsize"} /><br /></Typography>
                                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{jobSize}</Typography>
                                </Show>
                            </Show>
                            <Show
                                when={rating}
                            >
                                <Typography sx={{ py: 1 }} fontSize="80%" color="success.dark">
                                    <CandidateRatings rating={rating} numberOfFeedbacks={numberOfRatings} showWithFilter={false} />


                                </Typography>
                            </Show>

                        </Stack>
                    </Card>
                </Stack>


                {openDialog() && (
                    <Dialog open={true} onBackdropClick={handleCloseDialog} >
                        <Box
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();

                            }}
                        >
                            <DialogContent>
                                <Stack direction="row" margin={1}>
                                    <Avatar src={avatarSrc} >{anonymized !== "no" ? <BusinessIcon /> : ''} </Avatar>
                                    <Stack direction="column">
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body1" color="text.secondary">{avatarTxt} </Typography>
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{role}  </Typography>
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.secondary">{jobName}  </Typography>
                                        <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="success.dark">{wfStatus}</Typography>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Stack direction="column" alignItems="center" margin={1}>
                                    <Typography sx={{ px: 1 }} variant="body1"  ><GetTextUI formName={"workflowpage"} label={"selectnextaction"} /></Typography>
                                </Stack>

                                <Stack spacing={isExtraSmall() ? 0 : 1}>

                                    <DrawWfButtonContent />

                                </Stack>
                            </DialogContent>

                        </Box>
                    </Dialog>
                )}


            </Box >

        </>
    )
}

export { AvatarWithWfButtons }
