const getMinimum = (v1: string, v2: string, v3: string): number => {
    let num1 = parseFloat(v1);
    let num2 = parseFloat(v2);
    let num3 = parseFloat(v3);
  
    if (isNaN(num1) || num1 <= 0) {
      num1 = Number.POSITIVE_INFINITY;
    }
    if (isNaN(num2) || num2 <= 0) {
      num2 = Number.POSITIVE_INFINITY;
    }
    if (isNaN(num3) || num3 <= 0) {
      num3 = Number.POSITIVE_INFINITY;
    }
  
    return Math.min(num1, num2, num3);
  }
  export { getMinimum }